import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import 'app.css';
import App from 'app/App';

const rootEl = document.getElementById('root');
ReactDOM.render(<App />, rootEl);

// comment in for PWA with service worker in production mode
// registerServiceWorker();

import React from 'react';
import { useStyles } from 'app/appStyle';
import { Route, Switch } from 'react-router-dom';

const PrivateRoutes = React.lazy(() => import('./PrivateRoutes'));
export const GUEST_ROUTES = ['/createAccount'];

export default function Routes() {
	const classes = useStyles();
	const isGuest = GUEST_ROUTES.includes(window.location.pathname);

	return (
		<div className={isGuest ? classes.rootGuest : classes.root}>
			<div className={classes.appFrame}>
				<Switch>
					{/*  PUBLIC */}
					<Route
						exact
						path="/createAccount"
						component={React.lazy(() => import('components/createAccount/CreateMaestroAccountForm'))}
					/>
					<PrivateRoutes />
				</Switch>
			</div>
		</div>
	);
}
